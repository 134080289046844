import {Bar} from 'vue-chartjs'

export default {
  extends: Bar,
  // this.props: { 
  //   label1: {
  //     type: Array
  //   },
  // },
  mounted() {
    
    
    setTimeout(async() => {

      let monthlySaleLabels = await JSON.parse(localStorage.getItem("monthlySaleLabels"))
      let monthlySaleValues = await JSON.parse(localStorage.getItem( "monthlySaleValues"))

      // eslint-disable-next-line no-console
      // console.log(monthlySaleValues)

      this.renderChart({
        labels: monthlySaleLabels != null && monthlySaleLabels.length > 0 ? monthlySaleLabels : [],
        datasets: [
          {
            label: 'Sales',
            backgroundColor: 'rgba(17,167,139,0.2)',
            borderColor: 'rgba(17,167,139,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(17,167,139,0.4)',
            hoverBorderColor: 'rgba(17,167,139,1)',
            borderCapStyle: 'round',
            data: monthlySaleValues != null && monthlySaleValues.length > 0 ? monthlySaleValues : [],
          }
        ]
      }, {responsive: true, maintainAspectRatio: false})

    }, 10)
    

  },


}
